<template>
  <div class="wrapper-home">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="header-thumb">
      <div class="thumb-bg">
        <img src="@/assets/img/banner.jpg" alt="">
      </div>
    </section>

    <section class="welcome">
      <div class="welcome-main">
        <div class="welcome-title">Welcome to CAT MONEY</div>
        <div class="welcome-img">
          <img src="@/assets/img/cat-lucky.png" alt="">
        </div>
        <div class="welcome-content">
          <p>Như các bạn đã biết, Mèo Thần Tài tượng trưng cho sự may mắn, tiền tài cho công việc kinh doanh đầu tư phát
            đạt. Hình ảnh chú Mèo vẫy tay như một biểu tượng đem lại may mắn cho các bạn. Du khách phương Tây lần đầu
            nhìn
            thấy đều nghĩ rằng con mèo đang làm động tác chào tạm biệt, nhưng trên thực tế, đó là hành động "gọi khách"
            đến cửa hàng cũng như mèo gọi các bạn tham gia đầu tư để mèo đem lại tài lộc với những khoản đầu tư với lãi
            suất hấp dẫn.</p>
          <p>Cùng Mèo Thần Tài điểm qua các tài lộc đầu tư với lãi suất hấp dẫn mèo đem lại cho các bạn. Mèo sẽ chọn bạn
            và đem lại cho bạn thời gian đầu tư và lãi suất phù hợp gồm 4 mốc thời gian sau đây:</p>
          <div class="my-1">
            <p>• Đầu tư 3 ngày bạn sẽ nhận được 8% lãi suất</p>
            <p>• Đầu tư 5 ngày bạn sẽ nhận được 13% lãi suất</p>
            <p>• Đầu tư 7 ngày bạn sẽ nhận được 18% lãi suất</p>
            <p>• Đầu tư 12 ngày bạn sẽ nhận được 25% lãi suất</p>
          </div>
          <p>Và đặc biệt với gói đầu tư Mèo Vàng sẽ gọi tên bạn nếu bạn may mắn với thời gian đầu tư sau đây:</p>
          <div class="my-1">
            <p>• Đầu tư 1 tháng bạn sẽ nhận được 30% lãi suất</p>
            <p>• Đầu tư 2 tháng bạn sẽ nhận được 40% lãi suất</p>
            <p>• Đầu tư 3 tháng bạn sẽ nhận được 50% lãi suất</p>
          </div>

          <p>Cùng với đó bạn sẽ nhận được Mèo Thần Tài cho cơ hội quay thưởng may mắn nhân nhiều lần giá trị tài sản
            đáng
            mơ ước và hấp dẫn</p>
          <p>Cuối cùng vô cùng đặc biệt vào dịp cuối năm tổng kết thông tin top 20 người giới thiệu mà Mèo Thần Tài muốn
            thay lời tri ân bằng những giải thưởng vô cùng đặc biệt và hấp dẫn để cảm ơn các thành viên đã cùng Mèo Thần
            Tài phát triển và tạo ra nhiều tài lộc đến với tất cả các bạn. Gồm các giải thưởng sau đây:</p>
          <div class="my-1">
            <p>• 01 Giải Đặc Biệt: 1 xe Porsche Taycan</p>
            <p>• 01 Giải Nhất: 15 cây vàng 9999</p>
            <p>• 01 Giải Nhì: 10 cây vàng 9999</p>
            <p>• 01 Giải Ba: 5 cây vàng 9999</p>
            <p>• 16 Giải Khuyến Khích: mỗi giải 1 cây vàng 9999</p>
          </div>
          <p>Lời nói sau cùng, Mèo Thần Tài xin cảm ơn các thành viên đã đăng kí tài khoản, tin tưởng và cùng tham gia
            phát triển đầu tư dự án mà Mèo Thần Tài mang lại cho các thành viên.</p>
          <p class="text-warning">* Chú ý: Ngày tổng kết và trao giải cho người giới thiệu sẽ đóng vào ngày 28/02/2025
          </p>
        </div>

      </div>
    </section>

    <section class="gift">
      <div class="gift-porsche">
        <div class="gift-first_title">
          <p>Giải đặc biệt</p>
          <p class="mt-2">01 siêu xe Porsche</p>
        </div>
        <div class="gift-porsche_img">
          <img src="@/assets/img/porsche2.png" alt="">
        </div>
      </div>

      <div class="gift-second">
        <div class="gift-first_title">
          <p>Giải nhất</p>
          <p class="mt-2">15 cây vàng</p>
        </div>
        <div class="gift-second_img">
          <img src="@/assets/img/gold.png" alt="">
        </div>
      </div>

      <div class="gift-third">
        <div class="gift-first_title">
          <p>Giải nhì</p>
          <p class="mt-2">10 cây vàng</p>
        </div>
        <div class="gift-third_img">
          <img src="@/assets/img/gold2.png" alt="">
        </div>
      </div>

      <div class="gift-second">
        <div class="gift-first_title">
          <p>Giải ba</p>
          <p class="mt-2">5 cây vàng</p>
        </div>
        <div class="gift-second_img">
          <img src="@/assets/img/gold3.png" alt="">
        </div>
      </div>

      <div class="gift-fourth">
        <div class="gift-first_title">
          <p>16 Giải khuyến khích</p>
          <p class="mt-2">1 cây vàng</p>
        </div>
        <div class="gift-fourth_img">
          <img src="@/assets/img/gold4.png" alt="">
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>
<script>
import Footer from '@/views/user/components/Footer'
export default {
  components: { Footer },
  mounted() {
    if (!localStorage.getItem('hasReloaded')) {
      // Nếu chưa reload, thực hiện reload trang
      localStorage.setItem('hasReloaded', 'true');
      location.reload();
    } else {
      // Nếu đã reload trang, xóa localStorage để không reload nữa
      localStorage.removeItem('hasReloaded');
    }
  }
}
</script>

<style></style>